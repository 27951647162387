export default [
    {
        path: "/events",
        name: "events",
        component: () => import(/* webpackChunkName: "events" */ "@/views/events/EventList.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/events/:id/settings",
        name: "event_settings",
        props: true,
        component: () => import(/* webpackChunkName: "event_settings" */ "@/views/events/EventSettings.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/events/:id/ticketSettings",
        name: "event_ticket_settings",
        props: true,
        component: () => import(/* webpackChunkName: "event_ticket_settings" */ "@/views/events/EventTicketSettings.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/events/:id/ticketManager",
        name: "event_ticket_manager",
        props: true,
        component: () => import(/* webpackChunkName: "event_ticket_manager" */ "@/views/events/EventTicketManager.vue"),
        meta: { requiresAuth: true }
    }
]
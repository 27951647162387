import { api, setToken, getToken } from "./client";
import store from "../store";
import router from "../router"

async function authenticate(body) {
    // Try to set initial token.
    setToken(getToken());

    const { data } = await api.post(
        "/api/account/login",
        {
            site: window.location.hostname,
            ...body
        }
    );
    
    if (data && data.authenticated) {
        store.commit("login", data);
        setToken(data.token);
    } else {
        store.commit("logout");
        router.push({ name: "login" });
        setToken("");
    }

    return { data };
}

export { authenticate }
export default [
    {
        path: "/committees",
        name: "view_committees",
        component: () => import(/* webpackChunkName: "committees" */ "@/views/committees/Committees.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/committees/members/:id",
        name: "view_committee_members",
        props: true,
        component: () => import(/* webpackChunkName: "committee_members" */ "@/views/committees/CommitteeMembers.vue"),
        meta: { requiresAuth: true }
    }
]
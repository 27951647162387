<template>
    <b-navbar
        type="dark"
        variant="dark"
        class="fixed-top"
    >
        <button
            @click="$emit('toggle-sidebar')"
            class="sc-toggler"
        >
            <font-awesome-icon icon="bars"></font-awesome-icon>
        </button>
        <b-navbar-brand
            class="text-light"
            :to="{ name: 'home' }"
        >
            <font-awesome-icon icon="tachometer-alt" class="mr-1"></font-awesome-icon>
            Dashboard
        </b-navbar-brand>
        <b-navbar-nav
            v-if="user"
            class="ml-auto"
        >
            <b-nav-item-dropdown
                href="#"
                right
            >
                <template v-slot:button-content>
                    <font-awesome-icon icon="user-circle"></font-awesome-icon>
                    Hello, {{ user.person.first_name }}!
                </template>
                <b-dropdown-item
                    href="https://my.hisacademyoh.com/user/account"
                    target="_blank"
                >
                    <font-awesome-icon class="mr-2" icon="user-cog" />
                    Account
                </b-dropdown-item>
                <b-dropdown-item
                    href="https://my.hisacademyoh.com/activity"
                    target="_blank"
                >
                    <font-awesome-icon class="mr-2" icon="user-shield" />
                    Activity
                </b-dropdown-item>
                <b-dropdown-item @click="logout">
                    <font-awesome-icon class="mr-2" icon="sign-out-alt"></font-awesome-icon>
                    Sign out
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </b-navbar>
</template>
<script>
import { mapState } from 'vuex';
export default {
    methods: {
        logout () {
            this.$store.commit("logout");
            this.$router.push("/login");
            this.$bvToast.toast("You are logged out.", {
                title: "Logged Out",
                autoHideDelay: 5000,
                appendToast: true
            });
        }
    },
    computed: mapState(["user"])
}
</script>
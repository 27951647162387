export default [
    {
        path: "/notifications",
        name: "notifications",
        component: () => import(/* webpackChunkName: "notifications" */ "@/views/notifications/Editor.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/notifications/add",
        name: "add_notification",
        component: () => import(/* webpackChunkName: "add_notification" */ "@/views/notifications/AdditNotification.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/notifications/edit/:id",
        props: true,
        name: "edit_notification",
        component: () => import(/* webpackChunkName: "edit_notification" */ "@/views/notifications/AdditNotification.vue"),
        meta: { requiresAuth: true }
    }
]
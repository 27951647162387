<template>
    <b-skeleton-wrapper :loading="loading">
        <template #loading>
            <div>
                <b-skeleton width="100%" class="mt-4" v-for="i in 5" :key="i"></b-skeleton>
            </div>
        </template>
        <ul v-if="group" class="nav">
            <li
                class="nav-item"
                v-for="(navbarItem, idx) in navbarItems"
                :key="idx"
            >
                <a
                    v-if="navbarItem.href"
                    :href="navbarItem.href"
                    class="nav-link"
                >
                    <font-awesome-icon style="width: 50px;" :icon="navbarItem.icon" size="lg"></font-awesome-icon>
                    {{ navbarItem.label }}
                </a>
                <router-link
                    v-else
                    @click="$emit('toggle')"
                    :to="navbarItem.to"
                    class="nav-link"
                    exact
                >
                    <font-awesome-icon style="width: 50px;" :icon="navbarItem.icon" size="lg"></font-awesome-icon>
                    {{ navbarItem.label }}
                </router-link>
            </li>
        </ul>
    </b-skeleton-wrapper>
</template>
<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            loading    : true,
            navbarItems: []
        };
    },
    mounted() {
        this.loadNavbarItems();
    },
    methods: {
        getNavbarItems() {
            this.$api.get(
                "/api/admin/get-navbar-items"
            ).then(({ data }) => {
                this.navbarItems = data.navbarItems;
                this.loading     = false;
                localStorage.setItem("navbar-items", this.makeLocalCookie(3600, data.navbarItems));
            });
        },
        makeLocalCookie(expires, data) {
            return new URLSearchParams({
                payload: encodeURI(JSON.stringify(data)),
                expires: (new Date()).getTime() + (expires * 1000)
            });
        },
        loadNavbarItems() {
            var navbarItems = localStorage.getItem("navbar-items");
            if (navbarItems == null)
            {
                this.getNavbarItems();
            } else {
                var localCookie = new URLSearchParams(navbarItems);
                var expires     = Number.parseInt(localCookie.get("expires"));

                if ((new Date().getTime()) > expires) {
                    this.getNavbarItems();
                } else {
                    this.navbarItems = JSON.parse(decodeURI(localCookie.get("payload")));
                    this.loading     = false;
                }
            }
        }
    },
    computed: mapState(["group"])
}
</script>
<template>
    <b-skeleton-wrapper class="mt-2" :loading="loading">
        <template #loading>
            <div>
                <b>Semester</b>
                <b-skeleton width="85%"></b-skeleton>
            </div>
        </template>
        <div>
            <template v-if="semester && semester.id">
                <div class="d-flex justify-content-between mt-3">
                    <b>Semester</b>
                    <router-link
                        :to="{ name: 'semesters' }"
                    >
                        <font-awesome-icon
                            icon="exchange-alt"
                            class="mr-1"
                        ></font-awesome-icon>
                        Change
                    </router-link>
                </div>
                <div class="f16 text-secondary">
                    <router-link
                        :to="{ name: 'edit_semester_settings', params: { id: semester.id } }"
                        class="text-secondary"
                    >
                        {{ semester.label }}
                    </router-link>
                </div>
                <div
                    class="d-flex justify-content-around mt-3"
                >
                    <router-link
                        :to="{ name: 'admin_form', params: {  formKey: 'edit-semester' }, query: { id: semester.id } }"
                    >
                        <font-awesome-icon
                            icon="pencil-alt"
                            class="mr-1"
                        />
                        Edit
                    </router-link>
                    <router-link
                        :to="{ name: 'edit_semester_settings', params: { id: semester.id } }"
                    >
                        <font-awesome-icon
                            icon="cog"
                            class="mr-1"
                        />
                        Settings
                    </router-link>
                </div>
            </template>
            <template v-else>
                <div>
                    <b>Semester</b>
                    <i>
                        <p>No semester is in progress.</p>
                    </i>
                    <div
                        class="d-flex justify-content-around mt-3"
                    >
                        <router-link
                            :to="{ name: 'admin_form', params: { formKey: 'add-semester' } }"
                        >
                            <font-awesome-icon
                                icon="plus-circle"
                                class="mr-1"
                            ></font-awesome-icon>
                            Add
                        </router-link>
                        <router-link
                            :to="{ name: 'semesters' }"
                        >
                            <font-awesome-icon
                                icon="exchange-alt"
                                class="mr-1"
                            ></font-awesome-icon>
                            Change
                        </router-link>
                    </div>
                </div>
            </template>
        </div>
    </b-skeleton-wrapper>
</template>
<script>
import { mapState } from 'vuex'

export default {
    computed: mapState(["semester"]),
    data() {
        return {
            loading:     true,
            permissions: {}
        };
    },
    mounted() {
        this.loadSemester();
    },
    methods: {
        loadSemester() {
            this.$api.get(
                "/api/admin/get-semester-info"
            ).then(({ data: { group, semester, permissions } }) => {
                this.$store.commit("setGroup", group);
                this.$store.commit("setSemester", semester);
                this.permissions = permissions;
                this.loading     = false;
            });
        }
    }
}
</script>
<style scoped>
    .f16 {
        font-size: 16pt;
    }
</style>
<template>
    <div>
        <div class="d-flex justify-content-between">
            <b>Group</b>
            <router-link
                :to="{ name: 'list_groups' }"
            >
                <font-awesome-icon
                    icon="exchange-alt"
                    class="mr-1"
                ></font-awesome-icon>
                Change
            </router-link>
        </div>
        <div class="f16" v-if="group">
            <router-link
                :to="{ name: 'home' }"
                class="text-secondary"
            >
                {{ group.group_name }}
            </router-link>
        </div>
        <b-alert
            v-else
            variant="info"
            show
            class="mt-3"
        >
            A group must be selected before you can view group options.
            <router-link
                to="/groups"
            >Select Group</router-link>
        </b-alert>
        <div v-if="group" class="d-flex justify-content-around mt-3">
            <router-link
                :to="{ name: 'invite_group_members', params: { group_id: group.group_id } }"
            >
                <font-awesome-icon
                    icon="plus-circle"
                    class="mr-1"
                ></font-awesome-icon>
                Invite
            </router-link>
            <router-link
                :to="{ name: 'group_settings', params: { group_id: group.group_id } }"
            >
                <font-awesome-icon
                    icon="cog"
                    class="mr-1"
                ></font-awesome-icon>
                Settings
            </router-link>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    computed: mapState(["group"])
}
</script>
<style scoped>
    .f16 {
        font-size: 16pt;
    }
</style>

export default [
    {
        path: "/users",
        name: "users",
        component: () => import(/* webpackChunkName: "users_manager" */ "@/views/users/UserManager.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/user/activity/:id",
        name: "user_activity",
        props: true,
        component: () => import(/* webpackChunkName: "users_activity" */ "@/views/users/UserActivity.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/user/waiting-list",
        name: "user_waiting",
        component: () => import(/* webpackChunkName: "user_waiting" */ "@/views/users/UserRequested.vue"),
        meta: { requiresAuth: true }
    }
];
import store from "../store";
import { base_url } from "./getBaseUrl";
import { api } from "./client";

async function getGroup() {
    const { data } = await api.get(
        `${base_url}/api/group/`,
        { params: { site: window.location.hostname } }
    );
    store.commit("setGroup", data.group);

    // Will arrive only if the user is logged in and is a member of the group.
    if (data.is_member) {
        store.commit("setSemester", data.semester);
        store.commit("setRole", Object.fromEntries(data.roles.map((role_name) => [role_name, true])));
    }

    store.commit("setLoading", false);
    store.commit("setRole", { "Member": data.is_member });
    return data;
}

export { getGroup }
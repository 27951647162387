import SCButton      from "@/components/sc/SCButton.vue";
import SCButtonGroup from "@/components/sc/SCButtonGroup.vue";
import SCCard        from "@/components/sc/SCCard.vue";
import SCCardGroup   from "@/components/sc/SCCardGroup.vue";

export default {
    install: function (Vue) {
        Vue.component("sc-btn",        SCButton);
        Vue.component("sc-btn-group",  SCButtonGroup)
        Vue.component("sc-card",       SCCard);
        Vue.component("sc-card-group", SCCardGroup);
    }
}
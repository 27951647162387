import axios from "axios";
import { base_url } from "./getBaseUrl";
import { successInterceptor, errorInterceptor } from "./interceptors"

const api = axios.create({
    baseURL: base_url,
    headers: {}
});

api.interceptors.response.use(successInterceptor, errorInterceptor);

function setHeaders(newHeaders) {
    var currentHeaders = api.defaults.headers || {};
    api.defaults.headers = { ...currentHeaders, ...newHeaders };
}

const STORATE_TOKEN_KEY = "token";
function getToken(tokenStorageKey) {
    return localStorage.getItem(tokenStorageKey || STORATE_TOKEN_KEY);
}

function setToken(token) {
    if (token) {
        setHeaders({ Authorization: "Auth " + token });
        localStorage.setItem(STORATE_TOKEN_KEY, token);
    }
}

function useTokenParam(paramName) {
    paramName = paramName || "token";
    let params = new URLSearchParams(document.location.search);
    let token = params.get(paramName);
    if (token) {
        setToken(token);
        document.location.search = "";
    }
}

export {
    api,
    setHeaders,
    setToken,
    getToken,
    useTokenParam
}
export default [
    {
        path: "/classes",
        name: "classes",
        component: () => import(/* webpackChunkName: "classes_table" */ "@/views/classes/ClassesTable.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/class/add",
        name: "add_class",
        component: () => import(/* webpackChunkName: "add_class" */ "@/views/classes/ClassForm.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/class/add2",
        name: "add_class2",
        component: () => import(/* webpackChunkName: "add_class2" */ "@/views/classes/AdditClass.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/class/edit/:id",
        props: true,
        name: "edit_class",
        component: () => import(/* webpackChunkName: "edit_class" */ "@/views/classes/ClassForm.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/class/view/:id",
        name: "single_class",
        props: true,
        component: () => import(/* webpackChunkName: "single_class" */ "@/views/classes/Class.vue"),
        meta: { requiresAuth: true }
    }
]
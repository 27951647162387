export default [
    {
        path: "/forms",
        name: "forms",
        component: () => import(/* webpackChunkName: "forms" */ "@/views/forms/FormList.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/form/:id",
        name: "form_root",
        component: () => import(/* webpackChunkName: "form_base" */ "@/views/forms/Base.vue"),
        props: true,
        meta: { requiresAuth: true },
        children: [
            {
                path: "",
                name: "form_home",
                component: () => import(/* webpackChunkName: "view_form" */ "@/views/forms/FormHome.vue"),
                props: true,
                meta: { requiresAuth: true }
            },
            {
                path: "edit",
                name: "edit_form",
                component: () => import(/* webpackChunkName: "edit_form" */ "@/views/forms/FormEditor.vue"),
                props: true,
                meta: { requiresAuth: true }
            },
            {
                path: "responses",
                name: "get_form_responses",
                component: () => import(/* webpackChunkName: "get_form_responses" */ "@/views/forms/FormResponseViewer.vue"),
                props: true,
                meta: { requiresAuth: true }
            },
            {
                path: "responses/sheet",
                name: "get_form_response_sheet",
                component: () => import(/* webpackChunkName: "get_form_response_sheet" */ "@/views/forms/FormResponseEditor.vue"),
                props: true,
                meta: { requiresAuth: true }
            },
            {
                path: "settings",
                name: "change_form_settings",
                props: true,
                component: () => import(/* webpackChunkName: "change_form_settings" */ "@/views/forms/FormSettings.vue"),
                meta: { requiresAuth: true }
            }
        ]
    }
]
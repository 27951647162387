<template>
    <button
        class="sc-btn pill"
        @click="click"
    >
        <slot name="default">
            {{ text }}
        </slot>
    </button>
</template>
<script lang="js">
export default {
    props: [
        "text",
        "to"
    ],
    methods: {
        click() {
            if(this.to) {
                this.$router.push(this.to);
            }
            this.$emit("click", ...arguments);
        }
    }
}
</script>
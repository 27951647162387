export default [
    {
        path: "/groups",
        name: "list_groups",
        component: () => import(/* webpackChunkName: "list_groups" */ "@/views/group/ListGroups.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/groups/add",
        name: "add_group",
        props: true,
        component: () => import(/* webpackChunkName: "add_group" */ "@/views/group/AddGroup.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/groups/:group_id/invite-members",
        name: "invite_group_members",
        props: true,
        component: () => import(/* webpackChunkName: "invite_group_members" */ "@/views/group/InviteMember.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/groups/:group_id/settings",
        name: "group_settings",
        props: true,
        component: () => import(/* webpackChunkName: "group_settings" */ "@/views/group/GroupSettings.vue"),
        meta: { requiresAuth: true }
    },
];
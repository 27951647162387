import Vue from 'vue'
import Vuex from 'vuex'
import { setGroupIdHeader, setSemesterIdHeader } from "@/api/helpers"
import { debugMode } from "@/api/debug"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        group               : {},
        debug               : debugMode,
        domain              : "",
        loggedIn            : false,
        loading             : false,
        user                : {},
        semester            : [],
        currentSemesterId   : null,
        permissions         : [],
        cached_roles: {}
    },
    mutations: {
        setGroup(state, payload) {
            state.group = payload;
            if (payload && payload.group_id) {
                setGroupIdHeader(payload.group_id);
            }
        },
        setSemester(state, payload) {
            state.semester = payload;
            if (payload && payload.id) {
                state.currentSemesterId = payload.id;
                setSemesterIdHeader(payload.id);
            }
        },
        setRole(state, roles) {
            state.cached_roles = { ...state.cached_roles, ...roles };
        },
        login (state, payload) {
            if (state.debug) console.log('setUserData payload = ', payload)
            state.user          = payload.user;
            state.permissions   = payload.permissions;
            state.loggedIn      = !(state.user == null);
        },
        setLoading (state, payload) {
            state.loading = payload;
        },
        setInterface (state, payload) {
            state.sites             = payload.sites;
            state.navbarItems       = payload.navbarItems;
        },
        logout (state) {
            localStorage.clear();
            sessionStorage.clear();
            Vue.$cookies.set('_auth', '');
            Vue.$cookies.set('session', '');
            state.user = null;
            state.loggedIn = false;
        }
    }
});
<template>
    <div>
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-1 text-muted">
            <span><font-awesome-icon icon="life-ring"></font-awesome-icon> Help</span>
        </h6>
        <ul class="nav flex-column mb-2 mx-3">
            <em>There are no help resources for this page/feature yet.</em>
            <router-link
            class="sc-btn pill mt-4"
            :to="{ name: 'admin_form', params: { formKey: 'help-request-form' } }"
            >
            <font-awesome-icon
                icon="question-circle"
                class="float-left m-1"
            ></font-awesome-icon>
            Request Help
            </router-link>
        </ul>
        <!--
        <ul class="nav flex-column mb-2">
            <li class="nav-item">
            <a class="nav-link" href="#">
                <span data-feather="file-text"></span>
                How to add a site.
            </a>
            </li>
        </ul>-->
    </div>
</template>
<script>
export default {
    
}
</script>
export default [
    {
        path: "/semesters",
        name: "semesters",
        component: () => import(/* webpackChunkName: "semesters" */ "@/views/semester/SemesterManager.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/semester/add",
        name: "add_semester",
        component: () => import(/* webpackChunkName: "add_semester" */ "@/views/semester/AdditSemester.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/semester/edit/:id",
        props: true,
        name: "edit_semester",
        component: () => import(/* webpackChunkName: "edit_semester" */ "@/views/semester/AdditSemester.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/semester/settings/:id",
        props: true,
        name: "edit_semester_settings",
        component: () => import(/* webpackChunkName: "edit_semester_settings" */ "@/views/semester/SemesterSettings.vue"),
        meta: { requiresAuth: true }
    }
]

<template>
  <div v-if="!loading" id="app">
    <top-navbar
      v-if="loggedIn"
      @toggle-sidebar="toggleSidebar()"
    ></top-navbar>
    <div class="container-desktop">
        <Sidebar
          v-model="show"
        />
        <main
          role="main"
          class="col-sm-12 col-md-8 ml-md-auto col-lg-10 pt-3"
        >
          <router-view
            class="col-sm-12 m-0 p-0"
          />
          <!-- v-bind:key changes router-view when site is changed. -->
        </main>
      </div>
  </div>
  <div v-else id="loading">
    <img class="mb-4" src="@/assets/logo.svg" alt="Logo" />
    <div class="custom-spinner pull-center mt-2"></div>
  </div>
</template>

<script>
import TopNavbar    from "@/components/navbar/TopNavbar.vue";
import Sidebar      from "@/components/sidebar/Sidebar.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { TopNavbar, Sidebar },
  data() {
    return {
      show: false
    };
  },
  methods: {
    toggleSidebar() {
      this.show = !this.show;
    }
  },
  computed: mapState(["loggedIn", "user", "group", "loading", "semester", "currentSemesterId", "title"])
}
</script>
<style scoped>
  .chip {
    padding: 0.5rem 1rem;
    border: 0.1 solid rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    background-color: #5455f8;
    color: white;
    font-weight: bold;
    margin: 0.2rem;
  }
</style>
<template>
    <nav
        v-if="loggedIn"
        id="sidebar-border"
        ref="sidebar"
        class="sidebar"
    >
        <div :class="['sidebar-sticky', (value) ? 'show' : '']">
            <b-button-close
                class="mobile-only"
                @click="$emit('input', !value)"
            >
                &times;
            </b-button-close>
        <current-group-widget />
        <hr/>
        <current-semester-widget />
        <hr/>
        <SidebarItems
            @toggle="$emit('input', !value)"
        />
        <hr/>
        <help-widget />
        <!-- TODO: Add feedback feature.
            <hr/>
            <div class="text-center">
            <button class="sc-btn pill">
                <font-awesome-icon icon="star" class="mr-2"></font-awesome-icon>
                Give Feedback
            </button>
            </div>
            -->
        </div>
    </nav>
</template>
<script>
import { mapState } from 'vuex'
import CurrentSemesterWidget from "./widgets/CurrentSemesterWidget.vue";
import HelpWidget            from "./widgets/HelpWidget.vue";
import CurrentGroupWidget    from "./widgets/CurrentGroupWidget.vue";
import SidebarItems from './SidebarItems.vue';

export default {
    props: {
        value: { type: Boolean }
    },
    components: { CurrentSemesterWidget, HelpWidget, CurrentGroupWidget, SidebarItems },
    computed: mapState(["loggedIn"])
}
</script>
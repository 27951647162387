import router from "@/router";

function errorInterceptor(error) {
    if (error.response) {
        const response = error.response;

        if (response.status == 401) {
            /* router.push({
                name: "error_page",
                params: { errorName: response.status },
                query: { error: response.message }
            }); */
            return response;
        }

        if (!response.status) {
            /* router.push({
                to     : "error_page",
                params : { errorName: "networkError" }
            }); */
        } else {
            const data = (response.data || {});
            
            if (data.authenticated == false && data.redirect) {
                router.push({ name: data.redirect });
            }
            return response;
        }
    }
    return error;
}

export { errorInterceptor }
import { setHeaders } from "./client"

function setGroupIdHeader(group_id) {
    if (group_id) {
        setHeaders({ "X-Group-Id": group_id });
    }
}

function setSemesterIdHeader(group_id) {
    if (group_id) {
        setHeaders({ "X-Semester-Id": group_id });
    }
}

export { setGroupIdHeader, setSemesterIdHeader }
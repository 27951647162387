export default [
    {
        path: "/signups",
        name: "signups",
        component: () => import(/* webpackChunkName: "signups" */ "@/views/signups/SignupList.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/signup/:id/members",
        name: "signup_members",
        props: true,
        component: () => import(/* webpackChunkName: "signup_members" */ "@/views/signups/SignupMembers.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/signup/:id/list",
        name: "signup_list",
        props: true,
        component: () => import(/* webpackChunkName: "signup_list" */ "@/views/signups/SignupPersonList.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/signup/:id/settings",
        name: "signup_settings",
        props: true,
        component: () => import(/* webpackChunkName: "signup_settings" */ "@/views/signups/SignupSettings.vue"),
        meta: { requiresAuth: true }
    }
]
import Vue    from "vue"
import Router from "vue-router"

import store  from "@/store"

import authorization_routes from "./routes/authorization_routes"
import user_routes          from "./routes/user_routes"
import form_routes          from "./routes/form_routes"
import group_routes         from "./routes/group_routes"
import event_routes         from "./routes/event_routes"
import committee_routes     from "./routes/committee_routes"
import semester_routes      from "./routes/semester_routes"
import class_routes         from "./routes/class_routes"
import signup_routes        from "./routes/signup_routes"
import notification_routes  from "./routes/notification_routes"

import { getGroup } from "@/api/getGroup"
import { authenticate } from "@/api/authenticate"

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import(/* webpackChunkName: "home" */ "@/views/home/Home.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/login",
      name: "login",
      component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
    },
    {
      path: "/students",
      name: "students",
      component: () => import(/* webpackChunkName: "students_table" */ "@/views/students/StudentTable.vue"),
      meta: { requiresAuth: true }
    },
    ...class_routes,
    ...signup_routes,
    {
      path: "/tracebacks",
      name: "tracebacks",
      component: () => import(/* webpackChunkName: "traceback_table" */ "@/views/traceback/TracebackTable.vue"),
      meta: { requiresAuth: true }
    },
    ...notification_routes,
    ...semester_routes,
    ...form_routes,
    ...event_routes,
    {
      path: "/admin/form/:formKey",
      name: "admin_form",
      props: true,
      component: () => import(/* webpackChunkName: "admin_form" */ "@/views/admin/AdminForm.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/import/:import_name",
      name: "admin_import",
      props: true,
      component: () => import(/* webpackChunkName: "import" */ "@/views/admin/AdminImport.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/assign-relationship",
      name: "assign_relationship",
      component: () => import(/* webpackChunkName: "assign_relationship" */ "@/views/AssignRelationship.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: "/file/:type/:filename",
      name: "view_file",
      component: () => import(/* webpackChunkName: "view_file" */ "@/views/file-viewer/FileViewer.vue"),
      props: true,
      meta: { requiresAuth: true }
    },
    ...user_routes,
    ...authorization_routes,
    ...group_routes,
    ...committee_routes,
    {
      path: "*",
      name: "not_found",
      component: () => import(/* webpackChunkName: "not_found" */ "@/views/NotFound.vue"),
      meta: { requiresAuth: true }
    }
  ],
  linkActiveClass: "active"
})

async function beforePageLoad() {
  store.commit("setLoading", true);
  const response = await authenticate();
  await getGroup();
  store.commit("setLoading", false);
  return response;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.loggedIn) {
      beforePageLoad().then(({ data: { authenticated }}) => {
        if (authenticated) next();
        else next({
          path: "/login",
          query: { redirect: to.fullPath }
        });
      });
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router;
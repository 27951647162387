import Vue          from 'vue'
import VueCookies   from 'vue-cookies'

Vue.use(VueCookies);

import App          from './App.vue'
import router       from './router/index'
import store        from './store'
import api          from './api'
import { BootstrapVue, BVToastPlugin, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomComponents from "./components"

import axios from 'axios'
import VueAxios from 'vue-axios'

import './assets/css/mainstyle.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueExcelEditor from "vue-excel-editor"

Vue.use(api);
Vue.use(BootstrapVue)
Vue.use(BVToastPlugin)
Vue.use(IconsPlugin)
Vue.use(VueExcelEditor)
Vue.use(CustomComponents);

library.add(fas)
library.add(far)
library.add(fab)

Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'))

Vue.component('font-awesome-icon', FontAwesomeIcon)

//Vue.config.productionTip = false

// Login with token parameter
import { useTokenParam } from './api/client';
useTokenParam();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
